define("bottomline-execution/components/material-calculation", ["exports", "@ember/array", "@ember/component", "@ember/string", "@ember/object", "@ember/utils", "@ember/service", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/unit-of-calculation", "bottomline-execution/utils/grouped-totals"], function (_exports, _array, _component, _string, _object, _utils, _service, _appState, _unitOfCalculation, _groupedTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, _unitOfCalculation.default, {
    targetTotal: (0, _object.computed)(function () {
      return 'total' + (0, _string.capitalize)(this.get('unitOfCalculationQty'));
    }),
    currentTruckInventory: (0, _service.inject)(),
    truck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('appState').getTruck();
    }),
    selectedAndIsDelivery: (0, _object.computed)('selectedTripStop', function () {
      return this.get('selectedTripStop') && this.get('selectedTripStop.isUnload');
    }),
    materialTransferTripStopTruckStorages: (0, _object.computed)('trip', function () {
      return [].concat.apply([], this.get('trip').transferStops().map(function (transferStop) {
        return [].concat.apply([], transferStop.get('trip_stop_truck_storages').toArray().filter(function (tsts) {
          return tsts.get('ActivityCode') === 'M';
        }));
      }));
    }),
    doesMaterialTransferExist: (0, _object.computed)('materialTransferTripStopTruckStorages', function () {
      return !(0, _utils.isEmpty)(this.get('materialTransferTripStopTruckStorages'));
    }),
    calculatedMaterials: (0, _object.computed)('trip.state', 'trip.trip_stops.@each.confirmedQty', 'trip.trip_stops.@each.confirmed15Qty', 'trip.trip_stops.@each.trip_stop_truck_storages', 'selectedAndIsDelivery', 'materialTransferTripStopTruckStorages', function () {
      var _this = this;

      var targetQuantity = this.get('unitOfCalculationQty');
      var trip = this.get('trip');
      var startStopStorages = trip.startInventoryStop().get('trip_stop_truck_storages').toArray();
      var startGroupedByMaterial = this.totalMaybeConfirmedQtyByMaterial(startStopStorages);
      var loadStopStorages = (0, _utils.isPresent)(trip.get('loadStop')) ? trip.get('loadStop.trip_stop_truck_storages').toArray() : [];
      var loadGroupedByMaterial = this.totalMaybeConfirmedQtyByMaterial(loadStopStorages);
      var deliveryStopStorages = [].concat.apply([], trip.deliveryStops().map(function (e) {
        return e.get('trip_stop_truck_storages').toArray();
      }));
      var deliveryGroupedByMaterial = this.totalMaybeConfirmedQtyByMaterial(deliveryStopStorages);
      var correctionStopStorages = (0, _utils.isPresent)(trip.correctionStop()) ? trip.correctionStop().get('trip_stop_truck_storages').toArray() : [];
      var correctionGroupedByMaterial = this.totalMaybeConfirmedQtyByMaterial(correctionStopStorages);
      var materialTransferSourceTripStopTruckStoragesByMaterial = (0, _groupedTotals.groupedTotalsFor)(this.get('materialTransferTripStopTruckStorages').filter(function (tsts) {
        return tsts.get(targetQuantity) < 0;
      }), {
        groupBy: 'MaterialID',
        calculateSumFor: targetQuantity
      });
      var materialTransferTargetTripStopTruckStoragesByMaterial = (0, _groupedTotals.groupedTotalsFor)(this.get('materialTransferTripStopTruckStorages').filter(function (tsts) {
        return tsts.get(targetQuantity) > 0;
      }), {
        groupBy: 'MaterialID',
        calculateSumFor: targetQuantity
      });
      var materials = trip.materials().reject(function (m) {
        return m === null;
      }).map(function (material) {
        var started = _this.getPlannedOrConfirmedByMaterial(startGroupedByMaterial, material);

        var loaded = _this.getPlannedOrConfirmedByMaterial(loadGroupedByMaterial, material);

        var delivered = _this.getPlannedOrConfirmedByMaterial(deliveryGroupedByMaterial, material);

        var corrected = _this.getPlannedOrConfirmedByMaterial(correctionGroupedByMaterial, material);

        var leftOver = started + loaded + corrected - delivered;
        var materialRecord = trip.store.peekRecord('material', material);

        var result = _object.default.create({
          materialNameShort: materialRecord.get('MaterialNameShort'),
          materialID: material,
          materialStyle: materialRecord.get('style'),
          startsWith: started,
          loaded: loaded,
          delivered: delivered,
          corrected: corrected,
          leftOver: leftOver,
          currentTruckInventory: 0,
          plannedConfirmedForSelected: 0
        });

        var materialTransferSource = materialTransferSourceTripStopTruckStoragesByMaterial.find(function (tsts) {
          return tsts.groupId == material;
        });
        var materialTransferTarget = materialTransferTargetTripStopTruckStoragesByMaterial.find(function (tsts) {
          return tsts.groupId == material;
        });

        if (materialTransferSource) {
          result.materialTransferSourceQty = materialTransferSource[_this.get('targetTotal')];
          result.leftOver += materialTransferSource[_this.get('targetTotal')];
        }

        if (materialTransferTarget) {
          result.materialTransferTargetQty = materialTransferTarget[_this.get('targetTotal')];
          result.leftOver += materialTransferTarget[_this.get('targetTotal')];
        }

        if (result.materialTransferSourceQty && result.materialTransferTargetQty) {
          result.materialTransferQty = result.materialTransferSourceQty + result.materialTransferTargetQty;
        } else {
          result.materialTransferQty = result.materialTransferSourceQty || result.materialTransferTargetQty;
        }

        if (_this.get('selectedAndIsDelivery')) {
          var selectedStopStorages = _this.get('selectedTripStop.trip_stop_truck_storages').rejectBy('isTssSummaryRecord');

          var selectedGroupedByMaterial = _this.totalMaybeConfirmedQtyByMaterial(selectedStopStorages);

          var selected = _this.getPlannedOrConfirmedByMaterial(selectedGroupedByMaterial, material);

          result.setProperties({
            currentTruckInventory: _this.get('currentTruckInventory').quantityForMaterial(material),
            plannedOrConfirmedForSelected: selected
          });
        }

        return result;
      });
      return materials.filter(function (item) {
        return item.get('loaded') > 0 || item.get('delivered') > 0 || item.get('startsWith') > 0;
      });
    }),
    getPlannedOrConfirmedByMaterial: function getPlannedOrConfirmedByMaterial(groupedStorages, material) {
      var group = groupedStorages.find(function (group) {
        return group.groupId === material;
      });

      if (group) {
        return group.total;
      } else {
        return 0;
      }
    },
    totalMaybeConfirmedQtyByMaterial: function totalMaybeConfirmedQtyByMaterial(list) {
      var groupedResult = (0, _array.A)([]);
      var groups = (0, _array.A)((0, _array.A)(list).mapBy('MaterialID')).uniq();
      var targetQuantity = this.get('unitOfCalculationQty');
      groups.forEach(function (materialId) {
        var currentGroup = new _object.default();
        var itemsForGroup = list.filterBy('MaterialID', materialId);
        currentGroup.set('groupId', materialId);
        var total = itemsForGroup.reduce(function (prev, item) {
          if (isNaN(prev)) {
            prev = 0;
          }

          if (item.get('isTssSummaryRecord')) return parseInt(prev, 10); // NOTE: these conditions code can be optimized, but lets keep them as is for better explicitly

          if (item.get('trip_stop.isStartInventory')) {
            return parseInt(prev, 10) + parseInt(item.get(targetQuantity) || 0, 10);
          } else if (item.get('trip_stop.isUnload') && item.get('trip_stop.isConfirmed')) {
            return parseInt(prev, 10) + parseInt(item.get(targetQuantity) || 0, 10);
          } else if (item.get('trip_stop.isLoad') && item.get('trip_stop.isConfirmed')) {
            return parseInt(prev, 10) + parseInt(item.get(targetQuantity) || 0, 10);
          } else if (item.get('trip_stop.isUnload') && !item.get('trip_stop.isConfirmed')) {
            return parseInt(prev, 10) + parseInt(item.get('plannedQty') || 0, 10);
          } else if (item.get('trip_stop.isLoad') && !item.get('trip_stop.isConfirmed')) {
            return parseInt(prev, 10) + parseInt(item.get('plannedQty') || 0, 10);
          } else if (item.get('trip_stop.isCorrectionStop')) {
            return parseInt(prev, 10) + parseInt(item.get(targetQuantity) || 0, 10);
          } else {
            return parseInt(prev, 10);
          }
        }, 0);
        currentGroup.set('total', total);

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });
      return groupedResult;
    }
  });

  _exports.default = _default;
});