define("bottomline-execution/utils/color-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textColorOn = textColorOn;

  /* Based on
  - WCAG techniques G18 Ensuring that a contrast ratio of at least 4.5:1 exists between text
    (and images of text) and background behind the text: https://www.w3.org/WAI/WCAG22/Techniques/general/G18.html
  - MathML version of the relative luminance definition: https://www.w3.org/TR/WCAG21/relative-luminance.html
  - Basic JavaScript implementation of a WCAG 2.1 colour contrast algorithm that chooses between
    black or white depending on the colour provided: https://gist.github.com/Accudio/b9cb16e0e3df858cef0d31e38f1fe46f
  - Package to calculate the relative luminance of an RGB triplet color: https://github.com/tmcw/relative-luminance
  */
  var redCoefficient = 0.2126;
  var greenCoefficient = 0.7152;
  var blueCoefficient = 0.0722;

  function relativeLuminance(_ref) {
    var r = _ref.r,
        g = _ref.g,
        b = _ref.b;
    var redLuminance = luminance(r / 255);
    var greenLuminance = luminance(g / 255);
    var blueLuminance = luminance(b / 255);
    return redLuminance * redCoefficient + greenLuminance * greenCoefficient + blueLuminance * blueCoefficient;
  }

  function luminance(primaryColor) {
    return primaryColor <= 0.04045 ? primaryColor / 12.92 : adjustGamma(primaryColor);
  }

  function adjustGamma(color) {
    return Math.pow((color + 0.055) / 1.055, 2.4);
  }

  function contrast(relativeLuminance1, relativeLuminance2) {
    var L1 = Math.max(relativeLuminance1, relativeLuminance2);
    var L2 = Math.min(relativeLuminance1, relativeLuminance2);
    return (L1 + 0.05) / (L2 + 0.05);
  }

  function textColorOn(hexColor) {
    var rgbColor = hexToRGB(hexColor);
    var relativeColorLuminance = relativeLuminance(rgbColor);
    var contrastToWhite = contrast(relativeColorLuminance, 1); // White has a luminance of 1

    return contrastToWhite >= 4.5 ? '#FFFFFF' : '#000000';
  }

  function hexToRGB(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
});